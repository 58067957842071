import { combineReducers } from 'redux';
import accountReducer from './auth/reducer';
import usersReducer from './users/reducer';
import adminReducer from './admin/reducer';
import empresaReducer from './empresa/reducer';
import dashsReducer from './dashs/reducer';
import metasReducer from './metas/reducer';
import gruposReducer from './grupos/reducer';
import fatorReducer from './fator/reducer';
import campanhaReducer from './campanha/reducer';
import estoqueReducer from './estoque/reducer';
import curvaReducer from './curva/reducer';
import categoriasReducer from './categorias/reducer';
import logsReducer from './logs/reducer';
import colaboradoresReducer from './colaboradores/reducer';
import classificacoes from './classificacoes/reducer';

const reducers = combineReducers({
  account: accountReducer,
  users: usersReducer,
  admin: adminReducer,
  empresa: empresaReducer,
  dashs: dashsReducer,
  metas: metasReducer,
  grupos: gruposReducer,
  fator: fatorReducer,
  campanha: campanhaReducer,
  estoque: estoqueReducer,
  curva: curvaReducer,
  categorias: categoriasReducer,
  logs: logsReducer,
  colaboradores: colaboradoresReducer,
  classificacoes: classificacoes
});

export default reducers;
