import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import usersSagas from './users/saga';
import adminSagas from './admin/saga';
import empresaSagas from './empresa/saga';
import dashsSagas from './dashs/saga';
import metasSagas from './metas/saga';
import gruposSagas from './grupos/saga';
import fatorSagas from './fator/saga';
import campanhaSagas from './campanha/saga';
import estoqueSagas from './estoque/saga';
import curvaSagas from './curva/saga';
import categoriasSagas from './categorias/saga';
import logsSagas from './logs/saga';
import colaboradoresSagas from './colaboradores/saga';
import classificacaoLojaSagas from './classificacoes/saga';

export default function* rootSaga(getState: any) {
  yield all([
    authSagas(),
    usersSagas(),
    adminSagas(),
    empresaSagas(),
    dashsSagas(),
    metasSagas(),
    gruposSagas(),
    fatorSagas(),
    campanhaSagas(),
    estoqueSagas(),
    curvaSagas(),
    categoriasSagas(),
    logsSagas(),
    colaboradoresSagas(),
    classificacaoLojaSagas()
  ]);
}
